import React from 'react';
// import styled from '@emotion/styled';
import { Image, Text } from 'theme-ui';
import Layout from '../components/layout';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import styled from '@emotion/styled';

const articles = [
  [
    'https://www.biospace.com/assets/dist/images/logo.png;v=3d4a2d9c30152a980c1e355d5990da83',
    'https://biospace.com/article/uv-c-light-kills-sars-cov-2-triggering-novel-lighting-options-for-public-spaces/',
    'UV-C Light Kills SARS-CoV-2, Triggering Novel Lighting Options for Public Spaces',
  ],
  [
    'https://exchange4media.gumlet.io/news-photo/100947-expresslogo.jpg?format=webp&w=750&dpr=2.0',
    'https://indianexpress.com/article/technology/gadgets/uv-lights-are-becoming-the-weapon-of-choice-to-kill-the-virus-6520319/',
    'UV lights are becoming the weapon of choice to kill the virus',
  ],
  [
    'https://images-na.ssl-images-amazon.com/images/I/61pnzy1gnSL._AC_UL600_SR600,600_.png',
    'https://www.13abc.com/content/news/UV-light-could-keep-restaurnts-clean-when-customers-come-inside-again-570180161.html',
    'UV light could keep restaurants clean when customers come inside again',
  ],
  [
    'https://pbs.twimg.com/profile_images/529685794877747200/w6LLhkWK.jpeg',
    'https://www.laserfocusworld.com/lasers-sources/article/16555364/uv-sterilization-faruvc-light-kills-airborne-flu-viruses-without-danger-to-humans',
    'UV Sterilization: Far-UVC light kills airborne flu viruses without danger to humans',
  ],
];

const Img = styled(Image)`
  cursor: pointer;
`;

const BackgroundScience = () => {
  return (
    <Layout>
      <Text
        variant="subheading"
        width={1}
        py={[2, 4]}
        sx={{
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 'normal',
        }}
      >
        Background Science
      </Text>

      <ResponsiveMasonry
        columnsCountBreakPoints={{
          350: 1,
          750: 2,
          900: 3,
          1280: 4,
          1440: 5,
          1680: 6,
        }}
      >
        <Masonry>
          {articles.map((article) => (
            <div>
              <a href={`${article[1]}`} target="_blank">
                <Img
                  key={article[0]}
                  src={article[0]}
                  mr={[3]}
                  alt={`article`}
                  style={{
                    padding: '0.6rem 0',
                    margin: '0 auto',
                    // maxHeight: '500px',
                    // width: '100%',
                    maxWidth: '300px',
                    display: 'block',
                  }}
                />
              </a>
              <Text as="p">{article[2]}</Text>
            </div>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </Layout>
  );
};

export default BackgroundScience;
